@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Inter:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-blue: #0B7CDA;
  --secondary-blue-1: #1C70B5;
  --secondary-blue-2: #35A0F8;
  --primary-button: #4CDDA8;
  --accent-orange: #F47B20;
  --background-gray: #FFFFFF;
  --text-dark: #2C3E50;
  --text-light: #FFFFFF;
}

@layer base {
  html {
    font-size: 16px;
  }

  @screen sm {
    html {
      font-size: 18px;
    }
  }

  body {
    @apply font-inter text-body text-text-dark bg-background-gray;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-poppins text-primary-blue;
  }

  h1 {
    @apply text-2xl sm:text-3xl md:text-4xl font-bold;
  }

  h2 {
    @apply text-xl sm:text-2xl md:text-3xl font-semibold;
  }

  h3 {
    @apply text-lg sm:text-xl md:text-2xl font-semibold;
  }
}

@layer components {
  .subtitle {
    @apply font-inter text-subtitle font-semibold text-secondary-blue-1;
  }

  .btn {
    @apply font-inter text-body font-medium px-4 sm:px-6 py-2 sm:py-3 rounded-button transition duration-200 ease-in-out;
  }

  .btn-primary {
    @apply bg-primary-button text-text-light hover:bg-opacity-90;
  }

  .btn-secondary {
    @apply bg-text-light text-secondary-blue-1 border border-secondary-blue-1 hover:bg-secondary-blue-1 hover:text-text-light;
  }

  .btn-accent {
    @apply bg-secondary-blue-2 text-text-light hover:bg-opacity-90;
  }

  .card {
    @apply bg-white border border-gray-200 rounded-card shadow-card p-4 sm:p-6;
  }
}

@layer utilities {
  .content-area {
    @apply max-w-content mx-auto px-4 sm:px-6 lg:px-8;
  }

  .bg-primary-blue {
    background-color: var(--primary-blue);
  }

  .bg-secondary-blue-1 {
    background-color: var(--secondary-blue-1);
  }

  .bg-secondary-blue-2 {
    background-color: var(--secondary-blue-2);
  }

  .bg-primary-button {
    background-color: var(--primary-button);
  }

  .bg-accent-orange {
    background-color: var(--accent-orange);
  }

  .bg-background-gray {
    background-color: var(--background-gray);
  }

  .text-primary-blue {
    color: var(--primary-blue);
  }

  .text-secondary-blue-1 {
    color: var(--secondary-blue-1);
  }

  .text-secondary-blue-2 {
    color: var(--secondary-blue-2);
  }

  .text-text-dark {
    color: var(--text-dark);
  }

  .text-text-light {
    color: var(--text-light);
  }
}

/* Layout */
.grid-layout {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-4 sm:gap-6;
}

/* Spacing */
.section-margin {
  @apply my-8 sm:my-12;
}

.paragraph-margin {
  @apply mb-4;
}

.list-item-spacing > * + * {
  @apply mt-2;
}

/* Responsive */
@screen md {
  .content-area {
    @apply px-desktop;
  }
}

/* Accessibility */
.sr-only {
  @apply absolute w-px h-px p-0 -m-px overflow-hidden whitespace-nowrap border-0;
}

/* Animation */
.hover-effect {
  @apply transition-all duration-200 ease-in-out;
}

/* Custom scrollbar styles */
@layer utilities {
  /* Webkit browsers (Chrome, Safari, newer versions of Edge) */
  ::-webkit-scrollbar {
    @apply w-2;
  }

  ::-webkit-scrollbar-track {
    @apply bg-gray-100 rounded-full;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-primary-blue rounded-full;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-opacity-80;
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-blue) theme('colors.gray.100');
  }

  /* For Internet Explorer and Edge (older versions) */
  * {
    -ms-overflow-style: none;
  }

  *::-ms-scrollbar {
    @apply w-2;
  }

  *::-ms-scrollbar-track {
    @apply bg-gray-100;
  }

  *::-ms-scrollbar-thumb {
    @apply bg-primary-blue;
  }
}

/* Mobile-specific styles */
@media (max-width: 640px) {
  .mobile-full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .mobile-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
